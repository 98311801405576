<template>
    <v-dialog v-model="pDialog" max-width="520px">
        <v-card>
            <v-toolbar class="primary" dark>
                <span class="headline">{{ formTitle }}</span>
                <v-spacer/>
                <v-btn icon class="mr-3" @click="onCloseProduct">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                    @keyup.enter="save"
                                    filled
                                    maxlength="20"
                                    v-model="editedItem.name" label="Product Name*">
                                <v-icon slot="prepend">mdi-tag</v-icon>
                            </v-text-field>

                            <v-select
                                    v-model="editedItem.product_type" label="Product Type*"
                                    @change="getCategories"
                                    :items="productTypes"
                                    filled
                                    item-value="id"
                                    item-text="name"
                            >
                                <v-icon slot="prepend">mdi-tag-heart</v-icon>
                            </v-select>

                            <v-select
                                    v-model="editedItem.category" label="Product Categories*"
                                    :items="productCategories"
                                    filled
                                    item-value="id"
                                    item-text="name"
                            >
                                <v-icon slot="prepend">mdi-tag-text</v-icon>
                            </v-select>


                            <v-text-field
                                    @keyup.enter="save"
                                    filled
                                    type="number"
                                    min="1"
                                    v-model="editedItem.price" label="Price (GHS)*">
                                <v-icon slot="prepend">mdi-cash</v-icon>
                            </v-text-field>

                            <v-row >
                                <v-col cols="6">
                                    <v-text-field
                                            @keyup.enter="save"
                                            filled
                                            min="6"
                                            type="number"
                                            v-model="editedItem.weight" label="Weight(optional)">
                                        <v-icon slot="prepend">mdi-weight</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                            v-model="editedItem.units" label="Units (optional)"
                                            :items="['kg','g','lb']"
                                            filled
                                            item-value="id"
                                            item-text="name"
                                    >
                                        <v-icon slot="prepend">mdi-playlist-check</v-icon>
                                    </v-select>


                                </v-col>
                            </v-row>

                            <v-text-field
                                    @keyup.enter="save"
                                    filled
                                    v-model="editedItem.usage"
                                    label="Usage (eg. Home use, Restaurants, etc)">
                                <v-icon slot="prepend">mdi-waves</v-icon>
                            </v-text-field>
                            <v-text-field
                                    @keyup.enter="save"
                                    filled
                                    type="number"
                                    v-model="editedItem.minimum"
                                    min="1"
                                    label="Minimum Orders(optional)">
                                <v-icon slot="prepend">mdi-numeric-9-plus-circle</v-icon>
                            </v-text-field>

                            <uploader
                                    :isUpdate="is_update"
                                    :placeholder="editedItem"
                                    icon="mdi-image"
                                    label="Upload image"
                                    name="img_url"
                                    @change="setURL"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn rounded color="grey" text @click="close">Cancel</v-btn>
                <v-btn :disabled="canSave" :loading="is_saving" rounded color="primary" @click="save">
                    Save <v-icon right>mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Crud from "../../../gql/PrismaCrud.gql";
    import Uploader from '../../../components/datatable/FirebaseProductTypeImageUploader'

    export default {
      name: "AddProductDialog",
        components: {Uploader},
        data() {
            return {
                user: this.$store.getters['auth/getUserData'],
                ProductType: new Crud('productType'),
                Product : new Crud('product'),
                ProductCategory : new Crud('category'),
                createdProduct: {
                    product_type: ''
                },
                isLoading: false,
                is_saving: false,
                is_update: false,
                deleting: false,
                editedItem: {},
                productTypes: [],
                productCategories: [],
                img_upload_counter: 0,
                processedItem: {},
                productReturnType: `{
                    id
                    name
                    img_url
                    lazy_url
                    quantity
                    status
                    weight
                    units
                    minimum
                    usage
                    price
                    product_type{
                        id
                        name
                        slug
                        categories{
                        name
                        }
                    }
                    category{
                        id
                        name
                        slug
                    }
                    company{
                    id
                    name
                    location
                    }
                    createdAt
                }`,
            }
        },
      computed: {
          pDialog(){
              return this.$store.getters.getCreateItemDialog;
          },
          formTitle () {
              return this.is_update === false ? `New` : `Edit`
          },
          canSave: function () {
              if(this.img_upload_counter)
                  return !(
                      this.editedItem.name &&
                      this.editedItem.product_type &&
                      // this.editedItem.category &&
                      this.editedItem.price &&
                      this.editedItem.img_url
                  )
          }
      },
        methods:{
            onAddProduct(){
                this.$store.commit('setCreateProductDialog', true)
            },
            onCloseProduct() {
                this.$store.commit('setCreateProductDialog',false)
            },
            save () {
              this.user= this.$store.getters['auth/getUserData']
                this.is_saving = true
                this.formatSave()
                if (this.is_update === true) {
                    this.updateItem().then(_=>{
                        this.editedItem = {}
                        this.close()
                    })
                } else {
                    this.createItem().then(_=>{
                        this.editedItem = {}
                        this.close()
                    })
                }
            },
            getCategories(id){
                let productType = this.productTypes.find(type=>{
                    return type.id === id
                })
                this.productCategories = productType.categories
                setTimeout(()=>{
                    this.$root.$emit('setProductTypeSlug', productType.slug)
                }, 500)

            },
            setURL(res){
                this.editedItem.img_url = res.fileName
                this.img_upload_counter++
            },
            getProductTypes(){
                this.ProductType.find(`{id name slug categories{name id}}`)
                    .then(res=>{
                        this.productTypes = res.data
                    }).catch(err=>{
                    console.log(err)
                })
            },
            formatSave(){
                this.processedItem = {...this.editedItem}
                this.processedItem.weight = parseInt(this.processedItem.weight)
                this.processedItem.price = parseInt(this.processedItem.price)

                this.processedItem.product_type = { connect: { id : this.processedItem.product_type.id || this.processedItem.product_type} }
                this.processedItem.category = { connect: { id : this.processedItem.category.id || this.processedItem.category } }
                this.processedItem.company = { connect: { id : this.user.company.id } }
                if(this.processedItem.createdAt)
                    delete this.processedItem.createdAt
            },
            close () {
                this.img_upload_counter = 0
                this.onCloseProduct()
                this.$root.$emit('reset-img-uploader')
                setTimeout(() => {
                    this.editedItem = this.defaultItem
                    this.is_update = false
                }, 300)
                this.is_saving = false
                this.$router.push({name: 'agency-manage-products'})
            },
            createItem(){
                return this.Product.insert(this.productReturnType, {
                    data: this.processedItem
                }).then(res=>{
                    this.createdProduct = {...res, show: true}

                })
            },
        },

        created(){

            this.getProductTypes()
        }
    }
</script>

