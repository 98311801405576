<template>
  <div>
    <add-company-dialog></add-company-dialog>
    <add-product-dialog></add-product-dialog>
   <div v-if="products">
     <v-row v-if="!loading"  justify="center" >
       <v-col  :style="[disabled?'display:none':null]" cols="11" sm="6" md="3" lg="3">
         <router-link :to="{name: 'agency-manage-market-request'}">
           <v-card style="border-radius: 32px; min-height: 200px">
             <v-card-title class=" headline">
               <v-icon left>mdi-cart</v-icon>Market Requests
             </v-card-title>
             <v-card-text>
               <line-chart :chart-data="marketRequests"></line-chart>
             </v-card-text>
           </v-card>
         </router-link>
       </v-col>

       <v-col cols="11" sm="6" md="3" lg="3">
         <router-link :to="{name: 'agency-manage-transactions'}">
           <v-card style="border-radius: 32px; min-height: 200px">
             <v-card-title class=" headline">
               <v-icon left>mdi-cash</v-icon>Transactions
             </v-card-title>
             <v-card-text class=" text-center">
               <v-row>
                 <v-col cols="12" sm="6">
                   <h2 class="primary--text">Success</h2>
                   <p class="display-1">{{transactions.active}}</p>
                 </v-col>
                 <v-col cols="12" sm="6" >
                   <h2 class="error--text">Failed</h2>
                   <p class="display-1">{{transactions.pending}}</p>
                 </v-col>
               </v-row>
             </v-card-text>
           </v-card>
         </router-link>
       </v-col>

       <v-col cols="11" sm="6" md="3" lg="3">
         <router-link :to="{name: 'agency-manage-depot'}">
           <v-card style="border-radius: 32px; min-height: 200px">
             <v-card-title class=" headline">
               <v-icon left>mdi-home</v-icon>Depots
             </v-card-title>
             <v-card-text class=" text-center">
               <p class="display-3">{{depots}}</p>
             </v-card-text>
           </v-card>
         </router-link>
       </v-col>

       <v-col cols="11" sm="6" md="3" lg="3">
         <router-link :to="{name: 'agency-manage-products'}">
           <v-card style="border-radius: 32px; min-height: 200px">
             <v-card-title class=" headline">
               <v-icon left>mdi-tag-multiple</v-icon>Products
             </v-card-title>
             <v-card-text class=" text-center">
               <p class="display-3">{{products}}</p>
             </v-card-text>
           </v-card>
         </router-link>
       </v-col>
     </v-row>

     <v-row v-else justify="center" class="mt-3">
       <v-progress-circular
           :size="50"
           color="primary"
           indeterminate
       ></v-progress-circular>
     </v-row>
   </div>

    <div v-else>
          <v-row v-if="!loading">
            <v-col>
              <v-card
                  flat
                  class="mx-auto mt-5 justify-center transparent"
                  max-width="450"
              >
                <v-img
                    src="https://res.cloudinary.com/fitrip/image/upload/v1639917176/akokomarket/In_no_time_qsaep3.gif"
                    height="300px"
                ></v-img>

                <div style="margin-top:20px; border-radius:10px; text-align:center; overflow-wrap: initial">
                  <div class="headline grey--text text--darken-2">Please add a product <br /></div>
                </div>

                <div style="text-align:center;" width="100px" class="caption grey-text">
                  Adding your Products <br /> allows you to begin selling to customers.
                </div>

                <div class="grey--text pt-2" style="text-align:center;margin-top: 20px">
                  <v-btn
                      rounded
                      color="primary"
                      large
                      @click="onAddCompanyOrProduct"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add Product
                  </v-btn>
                  <v-spacer></v-spacer>
                </div>
              </v-card>
            </v-col>
          </v-row>
      <v-row v-else justify="center" class="mt-3">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-row>
    </div>


    </div>
</template>

<script>
  import {gql} from 'apollo-boost'
  import LineChart from '@/components/Charts/Pie'
  import AddCompanyDialog from "../../Website/Business/addCompanyDialog";
  import AddProductDialog from "../../Website/Business/addProductDialog";
  export default{

    components: {AddProductDialog, AddCompanyDialog, LineChart},
    data() {
      return {
  			user: this.$store.getters['auth/getUserData'],
        marketRequests: {},
        depots: 0,
        products: 0,
        transactions: {
          active: 0,
          pending: 0
        },
        loading: true,
        disabled:true,
      }
    },
    created(){
if(this.user.company){
  this.initDashboard();
}else {
  this.loading = false
}
    },
    methods: {
      initDashboard(){
        this.$apollo.query({
          query: gql`query marketRequests(
            $marketRequest: MarketRequestWhereInput
            $product: ProductWhereInput
            $depot: DepotWhereInput
            $transaction: TransactionWhereInput
          ){
          marketRequests(where: $marketRequest){status}
          products(where: $product){id}
          transactions(where: $transaction){id status}
          depots(where: $depot){ id }
        }
        `,
          variables: (()=>{
            let condition = {
              company: {
                id : this.user.company.id
              }
            }
            return {
              marketRequest : {
                products_some:{
                  product: condition
                }
              },
              product : {...condition, status: 'active'},
              depot : condition,
              transaction : condition,
            }
          })()
        }).then(res=>{
          console.log(res)
          this.setMarketRequestSummary(res.data.marketRequests)
          this.setTransactionsSummary(res.data.transactions)
          this.depots = res.data.depots.length
          this.products = res.data.products.length
          this.loading = false
        }).catch(err=>{
          console.error(err)
        })
      },
        onAddCompanyOrProduct(){
        if(!this.user.company){ this.$store.commit('setCompanyDialog', true)}
        else{
          this.$store.commit('setCreateProductDialog', true)
        }

      },
      setMarketRequestSummary(marketRequests){
        let summary = {
          pending: 0,
          success: 0,
          failed: 0,
          awaiting_pickup: 0
        }
        marketRequests.forEach(request=>{
          switch(request.status){
            case 'pending' :
              summary.pending += 1
              break
            case 'success' :
              summary.success += 1
              break
            case 'awaiting pickup' :
              summary.awaiting_pickup += 1
              break
            case 'failed' :
              summary.failed += 1
              break
          }
        })
        this.marketRequests.datasets = [
          {
              label: 'Pending',
              data: [summary.pending, 0],
              backgroundColor: [
                  '#29292933',
              ],
              borderColor: [
                  '#292929',
              ],
              borderWidth: 1
          },
          {
              label: 'Awaiting pickup',
              data: [summary.awaiting_pickup, 0],
              backgroundColor: [
                  '#ffa21a33',
              ],
              borderColor: [
                  '#ffa21a',
              ],
              borderWidth: 1
          },
          {
              label: 'Successful',
              data: [summary.success, 0],
              backgroundColor: [
                  '#5cb86033',
              ],
              borderColor: [
                  '#5cb860',
              ],
              borderWidth: 1
          },
          {
              label: 'Failed',
              data: [summary.failed, 0],
              backgroundColor: [
                  '#f55a4e33',
              ],
              borderColor: [
                  '#f55a4e',
              ],
              borderWidth: 1
          }
        ]
        console.log('marketRequests', summary)
      },

      setTransactionsSummary(transactions){
        let summary =  {
          active: 0,
          pending: 0
        }
        transactions.forEach(transaction=>{
          transaction.status == 'pending' ? summary.pending += 1 : summary.active += 1
        })
        this.transactions = summary
        console.log('transactions', summary)

      }
    }
  }
</script>
