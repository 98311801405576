<template>
    <v-dialog width="450" v-model="cdialog" persistent>
                <v-card class="py-7 px-8 form text-center"
                        style="border-radius:25px">
                    <p class="title">
                        Hi There, <br />
                        Please register your Business First.
                    </p>
                    <p class="grey--text font-weight-light">
                        Get to sell your products and monitor the growth of your business on our
                        platform
                    </p>
                    <v-card-text class="my-4">
                        <v-form>
                            <v-text-field
                                v-model="form.name"
                                placeholder="Business name"
                                outlined rounded
                                prepend-inner-icon="mdi-home"
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="form.phone"
                                placeholder="Business contact number"
                                outlined rounded
                                prepend-inner-icon="mdi-phone"
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="form.location"
                                placeholder="Business Location"
                                outlined rounded
                                prepend-inner-icon="mdi-map-marker"
                            >
                            </v-text-field>
                            <v-row justify="center">
                                <v-col cols="12" md="11" lg="9">
                                    <v-btn rounded @click="AddCompanyToUser" :loading="loading" color="primary" block large>
                                        <v-icon left>mdi-home-plus</v-icon>
                                        Continue
                                    </v-btn>
                                  <v-btn @click="closeCompanyDialog" text class="grey--text mt-2">
                                    <v-icon>mdi-close</v-icon>
                                     Cancel
                                  </v-btn>
                                </v-col>

                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
    </v-dialog>
</template>

<script>
    export default {
      name: "AddCompanyDialog",
        data() {
            return {
                loading: false,
                user: this.$store.getters['auth/getUserData'],
                form: {}
            }
        },
      computed: {
         cdialog(){
           return this.$store.getters.getCompanyDialog;
         }
      },
        methods:{
            AddCompanyToUser(){
                !(this.form.name && this.form.phone) ?
                    this.$error('fill in business name and contact number') :
                    (
                        this.loading = true,
                        this.$apollo.mutate({
                            mutation: require('@/gql/user.gql').update,
                            variables: {
                                where: {
                                    id : this.user.id
                                },
                                data: {
                                    account_type: "business",
                                    company: {
                                        create: {
                                            ...this.form,
                                            owner: `${this.user.first_name} ${this.user.last_name}`,
                                            status: "pending",
                                        }
                                    }
                                }
                            }
                        }).then(res=>{
                            this.$store.commit('auth/setUserData', res.data.updateUser)
                         this.closeCompanyDialog()
                            this.$success('Business created successfully')
                          this.$store.commit('setCreateProductDialog', true)
                            // this.$router.push({name: 'agency-manage-products'})
                        }).catch(err=>{
                            console.error(err);
                            this.$error('Unable to create business')
                        }).finally(_=>{
                            this.loading = false
                        })
                    )
            },
          closeCompanyDialog() {
            this.$store.commit('setCompanyDialog', false);
          },
        },

        created(){
            this.form.phone = this.user.phone
        }
    }
</script>
<style scoped>
    .form{
        border-radius: 342px;
    }
</style>
